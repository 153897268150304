import { useState } from 'react'
import { Table } from 'react-bootstrap';
import { format } from 'date-fns';
import cx from 'classnames';
import PopoverMenu from 'components/PopoverMenu';
import { getTjenestegruppeIcon } from 'components/IcebergIcon/utils';
import EditAbonnement from './components/EditAbonnement';
import CreateAbonnement from './components/CreateAbonnement';
import AbonnementApi from 'services/AdminportalenApi/AbonnementApi';
import { Abonnement, FakturaAdresse } from 'services/AdminportalenApi/KundeKortApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import formatAsCurrency from 'utils/formatAsCurrency';
import IcebergBadge from 'components/IcebergBadge';

interface Props {
	tsKundeId: string;
	abonnementer: Abonnement[];
	isNedlagt: boolean;
	onUpdated: () => void;
	filteredTjenestegrupper: string[];
	faktureringsinformasjon: FakturaAdresse[];
}

const AbonnementSection = (props: Props) => {
	const toaster = useToaster();

	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [selectedAbonnement, setSelectedAbonnement] = useState<Abonnement>(null);
	const [showInactive, setShowInactive] = useLocalStorage<boolean>('showInactiveAb', false);

	const handleEdit = (abonnement: Abonnement) => {
		setShowEditModal(true);
		setSelectedAbonnement(abonnement);
	}

	const handleDelete = async (id: string) => {
		try {
			await AbonnementApi.deleteAbonnement(id);
			toaster.success('Suksess', 'Abonnement slettet!');
			props.onUpdated();
		} catch (err) {
			toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
		}
	}

	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	const allAbonnementer = props.abonnementer.filter(x => showInactive ? x : (!x.validToDate || x.validToDate?.getTime() >= currentDate.getTime()));
	const filteredAbonnementer = allAbonnementer.filter(x => (props.filteredTjenestegrupper.length > 0 ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) : x));
	const inactiveAbonnementer = props.abonnementer.filter(x => (x.validToDate && x.validToDate?.getTime() < currentDate.getTime()) && (props.filteredTjenestegrupper.length > 0 ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) : x));

	const getFormattedPrice = (abonnement: Abonnement) => {
		if (abonnement.overstyrtPris || abonnement.overstyrtPris === 0) {
			return <>
				<span className='deactivated-currency' title='Standardpris'>{formatAsCurrency(abonnement.kalkulertPris)}</span> <span title='Overstyrt pris'>{formatAsCurrency(abonnement.overstyrtPris)}</span>
			</>;
		}
		if (abonnement.prisFaktorKalkulert) {
			return <>
				<span className='deactivated-currency' title='Standardpris'>{formatAsCurrency(abonnement.kalkulertPris)}</span> <span title='Faktorjustert pris'>{formatAsCurrency(abonnement.prisFaktorKalkulert)}</span>
			</>;
		}
		return formatAsCurrency(abonnement.kalkulertPris);
	};

	return (
		<>
			<div>
				<Table responsive className={cx(Style.table, { [Style.withHelpText]: props.filteredTjenestegrupper.length > 0 })} hover>
					<thead>
						<tr>
							<th style={{ width: '30%' }}>Abonnement</th>
							<th style={{ width: '10%', textAlign: 'end' }}>Pris (NOK)</th>
							{
								inactiveAbonnementer?.length > 0 ?
									(
										showInactive ?
											<th onClick={() => setShowInactive(prev => !prev)} style={{ width: '30%', cursor: 'pointer' }}>Status (Skjul inaktive) <IcebergIcon icon='arrow-up' /></th> :
											<th onClick={() => setShowInactive(prev => !prev)} style={{ width: '30%', cursor: 'pointer' }}>Status {'(' + inactiveAbonnementer.length + ' inaktive' + ', Vis' + ')'}<IcebergIcon icon='arrow-down' /></th>
									) :
									<th style={{ width: '30%' }}>Status</th>
							}
							<th>Tjenestegrupper</th>
							<th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
								{!props.isNedlagt && (
									<span onClick={() => setShowCreateModal(true)}>
										<IcebergIcon icon='plus' title='Ny' bold />
									</span>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						{
							filteredAbonnementer.map(abonnement => (
								<tr key={abonnement.abonnementId}>
									<td>{abonnement.tjenesteNavn}</td>
									<td style={{ textAlign: 'end' }} className="fitContent">{getFormattedPrice(abonnement)}</td>
									<td className="fitContent">
										{
											abonnement.validToDate && (abonnement.validToDate.getTime() - currentDate.getTime() < 0) ?
												<IcebergBadge variant='dark'>INAKTIV</IcebergBadge> :
												<IcebergBadge variant='primary'>AKTIV</IcebergBadge>
										}
										<span> ({format(abonnement.validFromDate, 'dd.MM.yyyy')} - {abonnement.validToDate && format(abonnement.validToDate, 'dd.MM.yyyy')})</span>
									</td>
									<td className='tjenesteGruppeCol'>
										<IcebergIcon icon={getTjenestegruppeIcon(abonnement.tjenesteGruppeNavn)} />
										{' ' + abonnement.tjenesteGruppeNavn}
									</td>
									<td className="fitContent">
										<PopoverMenu
											onDelete={() => handleDelete(abonnement.abonnementId)}
											onEdit={() => handleEdit(abonnement)}
											confirmDelete={true}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
				{props.filteredTjenestegrupper.length > 0 && <span className={Style.helpText}>Viser {filteredAbonnementer.length} av {allAbonnementer.length} abonnementer</span>}
			</div>
			{showEditModal &&
				<EditAbonnement
					show={showEditModal}
					setShow={setShowEditModal}
					onUpdated={props.onUpdated}
					tsKundeId={props.tsKundeId}
					abonnement={selectedAbonnement}
					onlyView={props.isNedlagt} />}
			<CreateAbonnement
				show={showCreateModal}
				setShow={setShowCreateModal}
				onUpdated={props.onUpdated}
				tsKundeId={props.tsKundeId}
				faktureringsinformasjon={props.faktureringsinformasjon} />
		</>
	)
}

export default AbonnementSection;