import { useState } from 'react'
import { Table } from 'react-bootstrap';
import PopoverMenu from 'components/PopoverMenu';
import EditFaktureringsinfo from './components/EditFaktureringsinfo';
import CreateFaktureringsinfo from './components/CreateFaktureringsinfo';
import TjenestegruppeConnectionsView from './components/TjenestegruppeConnectionsView';
import FakturaAdresserApi from 'services/AdminportalenApi/FakturaAdresserApi';
import { FakturaAdresse } from 'services/AdminportalenApi/KundeKortApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
	tsKundeId: string;
	faktureringsinformasjon: FakturaAdresse[];
	isNedlagt: boolean;
	onUpdated: () => void;
	filteredTjenestegrupper: string[];
	orgNr: string;
}

const Faktureringsinformasjon = (props: Props) => {
	const toaster = useToaster();

	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [selectedFakturaadresse, setSelectedFakturaadresse] = useState<FakturaAdresse>(null);

	const handleEdit = (fakturaadresse: FakturaAdresse) => {
		setShowEditModal(true);
		setSelectedFakturaadresse(fakturaadresse);
	}

	const handleDelete = async (id: string) => {
		try {
			await FakturaAdresserApi.deleteFaktureringsinfo(id);
			toaster.success('Suksess', 'Fakturaadresse slettet!');
			props.onUpdated();
		} catch (err) {
			toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
		}
	}

	const filteredFakturainfo = props.faktureringsinformasjon.filter((x: FakturaAdresse) => {
		if (props.filteredTjenestegrupper.length === 0 || x.tjenesteGruppeConnections.filter(val => val.selected).length === 0)
			return x;
		const notFound = x.tjenesteGruppeConnections.findIndex(val => val.selected && props.filteredTjenestegrupper.includes(val.tjenesteGruppeId)) === -1;
		return !notFound;
	}).sort((a, b) => (a > b ? 1 : -1));

	return (
		<>
			<div className={Style.wrapper}>
				<Table responsive hover>
					<thead>
						<tr>
							<th>Fakturainfo</th>
							<th></th>
							<th></th>
							<th>Pogo kundenummer</th>
							<th>Tjenestegrupper</th>
							<th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
								{!props.isNedlagt && (
									<span onClick={() => setShowCreateModal(true)} >
										<IcebergIcon icon='plus' title='Ny' bold />
									</span>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						{
							filteredFakturainfo.map(fakturaAdresse => (
								<tr key={fakturaAdresse.fakturaAdresseId}>
									<td>{fakturaAdresse.fakturaAdresseNavn}</td>
									<td>{fakturaAdresse.fakturaEmail}</td>
									<td>{fakturaAdresse.formatedAsOneLine}</td>
									<td className="fitContent">{fakturaAdresse.kundenummer}</td>
									<td className='tjenesteGruppeCol'>
										<TjenestegruppeConnectionsView connections={fakturaAdresse.tjenesteGruppeConnections.filter(x => x.selected)} />
									</td>
									<td className={Style.tdPopoverMenu}>
										<PopoverMenu
											onDelete={() => handleDelete(fakturaAdresse.fakturaAdresseId)}
											onEdit={() => handleEdit(fakturaAdresse)}
											confirmDelete={true}
											onlyView={props.isNedlagt} />
									</td>
								</tr>
							))}
					</tbody>
				</Table>
				{props.filteredTjenestegrupper.length > 0 && <span className={Style.helpText}>Viser {filteredFakturainfo.length} av {props.faktureringsinformasjon.length} Fakturaadresser</span>}
			</div>
			{showEditModal && <EditFaktureringsinfo show={showEditModal} setShow={setShowEditModal} fakturaadresse={selectedFakturaadresse} onUpdated={props.onUpdated} onlyView={props.isNedlagt} orgNr={props.orgNr} />}
			{showCreateModal && <CreateFaktureringsinfo show={showCreateModal} setShow={setShowCreateModal} onUpdated={props.onUpdated} tsKundeId={props.tsKundeId} orgNr={props.orgNr} />}
		</>
	)
}

export default Faktureringsinformasjon;