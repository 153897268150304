import { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import TjenesteTypeahead from "components/TjenesteTypeahead";
import DatePicker from "components/DatePicker";
import AbonnementApi from "services/AdminportalenApi/AbonnementApi";
import { CreateAbonnementCommand } from "services/AdminportalenApi/AbonnementApi/types";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useForm from "utils/hooks/useForm";
import useToaster from "utils/hooks/useToaster";
import Style from "../index.module.css";
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { FakturaAdresse } from "services/AdminportalenApi/KundeKortApi/types";
import { PrisIntervallDto, TjenesteDto, TjenesteMedPriserDto } from "services/AdminportalenApi/TjenesterApi/types";
import TjenesterApi from "services/AdminportalenApi/TjenesterApi";
import formatAsCurrency from "utils/formatAsCurrency";

interface Props {
  tsKundeId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
  faktureringsinformasjon: FakturaAdresse[];
}

const CreateAbonnement = (props: Props) => {
  // const [priceAdjustmentType, setPriceAdjustmentType] = useState<string>();
  const [showFakturaInfoSelector, setShowFakturaInfoSelector] =
    useState<boolean>(false);

  const toaster = useToaster();

  const initialValues: CreateAbonnementCommand = {
    tsKundeId: props.tsKundeId,
    tjenesteId: "",
    validFromDate: new Date(),
    itemCountForPricing: 0
  };

  const validate = (values: CreateAbonnementCommand) => {
    let errors: any = {};
    if (values.tjenesteId === "") {
      errors.tjenesteId = "Vennligst velg tjeneste";
    }
    return errors;
  };

  const getActiveTjenestePris = (tjeneste: TjenesteMedPriserDto) => {
    const aktivTjenestePris = tjeneste.tjenestePris.find((x) => x.erAktiv);
    return aktivTjenestePris;
  };

  const [selectedTjeneste, setSelectedTjeneste] = useState<TjenesteMedPriserDto>();

  const onTjenesteSelected = async (tjeneste: TjenesteDto) => {
    if (tjeneste) {
      const needToConnectTjenesteGruppe = props.faktureringsinformasjon.every(
        (x) =>
          x.tjenesteGruppeConnections
            .filter((y) => y.selected === true)
            .every((y) => y.tjenesteGruppeId !== tjeneste.tjenesteGruppeId)
      );
      setShowFakturaInfoSelector(needToConnectTjenesteGruppe);

      // get aktiv tjenestepris
      const tjenesteMedPris = await TjenesterApi.getTjenesteMedPris(tjeneste.tjenesteId);
      setSelectedTjeneste(tjenesteMedPris);

    } else {
      setShowFakturaInfoSelector(false);
    }
    handleChange("tjenesteId", tjeneste ? tjeneste.tjenesteId : "");
  };

  useEffect(() => {
    handleChange("tsKundeId", props.tsKundeId);
    // Sjekker om det bare er ett alternativ tilgjengelig
    if (props.faktureringsinformasjon.length === 1) {
      handleChange(
        "fakturaAdresseIdToUse",
        props.faktureringsinformasjon[0].fakturaAdresseId
      );
    } else {
      handleChange("fakturaAdresseIdToUse", undefined); // Nullstill valgt hvis det ikke er bare ett alternativ tilgjengelig
    }
  }, [props.tsKundeId]);

  const onSubmit = async (command: CreateAbonnementCommand) => {
    if (!isNaN(parseFloat(command.overriddenPrice?.toString() || ""))) {
      command.overriddenPrice = Number(command.overriddenPrice);
    } else {
      command.overriddenPrice = null;
    }

    try {
      await AbonnementApi.createAbonnement(command);
      toaster.success("Suksess", "Abonnement opprettet!");
      handleClose();
      props.onUpdated();
    } catch (err) {
      toaster.error("Noe gikk galt.", HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { values, errors, touched, loading, handleChange, handleSubmit } =
    useForm(initialValues, validate, onSubmit);

  const handleClose = () => props.setShow(false);

  const handleAntallChanged = (value: string) => {
    const antall = parseInt(value);
    handleChange("itemCountForPricing", antall);
  };

  const getStepAntall = (antall: number, prisIntervall: PrisIntervallDto[]) => {
    const intervals = prisIntervall.map((x) => x.antall);
    const step = intervals.reverse().find((x) => antall >= x);
    return step ?? -1;
  };

  return (
    <Modal centered show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Abonnement</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Tjeneste</Form.Label>
            <TjenesteTypeahead
              onHandleChange={(e) => onTjenesteSelected(e)}
              selectedId={values.tjenesteId}
              isInvalid={errors?.tjenesteId && touched?.tjenesteId}
            />
            {errors?.tjenesteId && touched?.tjenesteId && (
              <div className={Style.invalidFeedback}>{errors?.tjenesteId}</div>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Gyldig fra
            </Form.Label>
            <DatePicker
              onChange={(date) => handleChange("validFromDate", date)}
              selected={values.validFromDate}
            />
            <Form.Text>Månedspris justeres i forhold til oppstartsdato</Form.Text>
          </Form.Group>
          {showFakturaInfoSelector && (
            <Form.Group className="mb-3">
              <Form.Label>
                Knytt tjenestegruppe til fakturainfo (ellers så blir det ikke
                bonus)
              </Form.Label>
              <Form.Select
                aria-label="Fakturainfo select"
                onChange={(e) =>
                  handleChange("fakturaAdresseIdToUse", e.target.value)
                }
                style={{ borderRadius: "24px" }}
                value={values.fakturaAdresseIdToUse} // Sett verdien til det valgte fakturaadresse-ID-et
              >
                <option value="">Velg fakturainfo å knytte til</option>
                {props.faktureringsinformasjon.map((x) => (
                  <option key={x.fakturaAdresseId} value={x.fakturaAdresseId}>
                    {x.fakturaAdresseNavn} (
                    {x.kundenummer ?? "Mangler kundenummer"})
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          
          {/* extra input for nivåbasert prismodell */}
          {selectedTjeneste && getActiveTjenestePris(selectedTjeneste)?.prisModelType === 4 &&
            <Form.Group className="mb-3">
              <Form.Label>Antall</Form.Label>
              <Form.Control
                type="number"
                value={values.itemCountForPricing}
                onChange={(e) => handleAntallChanged(e.target.value)}
              />
              <Form.Text>Tjenestepris er nivåbasert, se tabell under</Form.Text>
              <Table>
                <thead>
                  <tr>
                    <th>Antall</th>
                    <th>Pris</th>
                  </tr>
                  </thead>
                  <tbody>
                    {getActiveTjenestePris(selectedTjeneste).prisIntervall.map(i => 
                      <tr className={getStepAntall(values.itemCountForPricing, getActiveTjenestePris(selectedTjeneste).prisIntervall) === i.antall ? 'table-success' : ''}>
                        <td>{i.antall}</td>
                        <td>{formatAsCurrency(i.pris)}</td>
                    </tr>)}
                  </tbody>
              </Table>
            </Form.Group>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button
            className="col"
            variant="outline-primary"
            onClick={handleClose}
          >
            <IcebergIcon size="lg" icon="close" /> Avbryt
          </Button>
          <Button
            variant="primary"
            className="col"
            type="submit"
            disabled={
              loading ||
              Object.keys(errors).length > 0 ||
              Object.keys(touched).length === 0
            }
          >
            <span>
              <IcebergIcon icon="disk" size="lg" /> Lagre
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateAbonnement;
