import { useState } from 'react'
import { Table } from 'react-bootstrap';
import { format } from 'date-fns';
import cx from 'classnames';
import PopoverMenu from 'components/PopoverMenu';
import { getTjenestegruppeIcon } from 'components/IcebergIcon/utils';
import { Avtale } from 'services/AdminportalenApi/KundeKortApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import CreateAvtale from './components/CreateAvtale';
import AvtaleApi from 'services/AdminportalenApi/AvtalerApi';
import EditAvtale from './components/EditAvtale';
import IcebergBadge from 'components/IcebergBadge';

interface Props {
	tsKundeId: string;
	avtaler: Avtale[];
	isNedlagt: boolean;
	onUpdated: () => void;
	filteredTjenestegrupper: string[];
}

const AvtaleSection = (props: Props) => {
	const toaster = useToaster();

	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [selectedAvtale, setSelectedAvtale] = useState<Avtale>(null);
	const [showInactive, setShowInactive] = useLocalStorage<boolean>('showInactiveAb', false);

	const handleEdit = (avtale: Avtale) => {
		setShowEditModal(true);
		setSelectedAvtale(avtale);
	}

	const handleDelete = async (id: string) => {
		try {
			await AvtaleApi.deleteAvtale(id);
			toaster.success('Suksess', 'Avtale slettet!');
			props.onUpdated();
		} catch (err) {
			toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
		}
	}

	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	const allAvtaler = props.avtaler.filter(x => showInactive ? x : (!x.tilOgMed || x.tilOgMed?.getTime() >= currentDate.getTime()));
	const filteredAvtaler = allAvtaler.filter(x => (props.filteredTjenestegrupper.length > 0 ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) || !x.tjenesteGruppeId : x));
	const inactiveAvtaler = props.avtaler.filter(x => (x.tilOgMed && x.tilOgMed?.getTime() < currentDate.getTime()) && (props.filteredTjenestegrupper.length > 0 ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) : x));

	return (
		<>
			<div className={Style.wrapper}>
				<Table responsive hover>
					<thead>
						<tr>
							<th>Avtaler</th>
							<th></th>
							{
								inactiveAvtaler?.length > 0 ?
									(
										showInactive ?
											<th onClick={() => setShowInactive(prev => !prev)} style={{ cursor: 'pointer' }}>Status (Skjul inaktive) <IcebergIcon icon='arrow-up' /></th> :
											<th onClick={() => setShowInactive(prev => !prev)} style={{ cursor: 'pointer' }}>Status {'(' + inactiveAvtaler.length + ' inaktive' + ', Vis' + ')'}<IcebergIcon icon='arrow-down' /></th>
									) :
									<th>Status</th>
							}
							<th>Tjenestegrupper</th>
							<th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
								{!props.isNedlagt && (
									<span onClick={() => setShowCreateModal(true)} >
										<IcebergIcon icon='plus' title='Ny' bold />
									</span>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						{
							filteredAvtaler.map(avtale => (
								<tr key={avtale.avtaleId}>
									<td>{avtale.navn}</td>
									<td>{avtale.url ? (<a href={avtale.url + avtale.blobSasToken} target='_blank'><IcebergIcon icon='link' />Dokument</a>) : ''}</td>
									<td>
										{
											avtale.tilOgMed && (avtale.tilOgMed.getTime() - currentDate.getTime() < 0) ?
												<IcebergBadge variant='dark'>INAKTIV</IcebergBadge> :
												<IcebergBadge variant='primary'>AKTIV</IcebergBadge>
										}
										<span> ({format(avtale.fraOgMed, 'dd.MM.yyyy')} - {avtale.tilOgMed && format(avtale.tilOgMed, 'dd.MM.yyyy')})</span>
									</td>
									<td className='tjenesteGruppeCol'>
										{avtale.tjenesteGruppeNavn && (
											<div>
												<IcebergIcon icon={getTjenestegruppeIcon(avtale.tjenesteGruppeNavn)} />
												{' ' + avtale.tjenesteGruppeNavn}
											</div>
										)}
									</td>
									<td className="fitContent">
										<PopoverMenu
											onDelete={() => handleDelete(avtale.avtaleId)}
											onEdit={() => handleEdit(avtale)}
											confirmDelete={true}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
				{props.filteredTjenestegrupper.length > 0 && <span className={Style.helpText}>Viser {filteredAvtaler.length} av {allAvtaler.length} avtaler</span>}
			</div>
			{showEditModal &&
				<EditAvtale
					show={showEditModal}
					setShow={setShowEditModal}
					onUpdated={props.onUpdated}
					avtale={selectedAvtale}
					onlyView={props.isNedlagt} />}
			<CreateAvtale tsKundeId={props.tsKundeId} onUpdated={() => props.onUpdated()} show={showCreateModal} setShow={setShowCreateModal} />
		</>
	)
}

export default AvtaleSection;