import axios from 'axios';
import { OpprettTsKundeCommand, TsKundeDto, TsKundeOverviewDto, TsKundeSearchDto, updateTsKundeCommand, TsKundeSearchFilter } from './types';
import { getBaseUrl } from '..';

const baseUrl = getBaseUrl() + "/TsKunder";

const TsKunderApi = {

  search: (q: string, statusFilter?: string, size?: number): Promise<TsKundeSearchDto[]> => {
    let statusFilterQueryparameter = '';
    if (statusFilter)
      statusFilterQueryparameter = `&statusFilter=${statusFilter}`;
    if (!size)
      size = 8;
    return axios
      .get(`${baseUrl}/Search?q=${q}&size=${size}${statusFilterQueryparameter}`)
      .then((response) => response.data)
  },

  getById: (tsKundeId: string): Promise<TsKundeDto> =>
    axios.get(`${baseUrl}/${tsKundeId}`).then(response => response.data),

  getOverviewList: (filter: TsKundeSearchFilter, page: number): Promise<TsKundeOverviewDto[]> => {
    let aditionalQueryParameters = '';
    if (filter.overriddenOmsetningOnly) {
      aditionalQueryParameters += '&overrideOmsetningOnly=true';
    }
    if (filter.withoutAbonnement) {
      aditionalQueryParameters += '&withoutAbonnement=true';
    }
    if (filter.withoutAvtaler) {
      aditionalQueryParameters += '&withoutAvtaler=true';
    }
    if (filter.withoutFakturaAdresser) {
      aditionalQueryParameters += '&withoutFakturaAdresser=true';
    }
    if (filter.withoutKontaktpersoner) {
      aditionalQueryParameters += '&withoutKontaktpersoner=true';
    }
    if (filter.aktivtAbonnementTjenestegruppeId) {
      aditionalQueryParameters += `&aktivtAbonnementTjenestegruppeId=${filter.aktivtAbonnementTjenestegruppeId}`;
    }
    if (filter.aktivtAbonnementTjenesteId) {
      aditionalQueryParameters += `&aktivtAbonnementTjenesteId=${filter.aktivtAbonnementTjenesteId}`;
    }
    if (filter.aktivAvtaleTjenesteGruppeId) {
      aditionalQueryParameters += `&aktivAvtaleTjenesteGruppeId=${filter.aktivAvtaleTjenesteGruppeId}`;
    }
    if (filter.harDagligLederUtenEpost) {
      aditionalQueryParameters += `&harDagligLederUtenEpost=${filter.harDagligLederUtenEpost}`;
    }
    if (filter.manglerDagligLeder) {
      aditionalQueryParameters += `&manglerDagligLeder=${filter.manglerDagligLeder}`;
    }

    return axios
      .get(`${baseUrl}/AsOverviewDto?q=${filter.query}&page=${page}&orderBy=${filter.orderBy}&statusFilter=${filter.statusOption}${aditionalQueryParameters}`)
      .then((response) => response.data)
  },

  getTsKunderCount: (filter: TsKundeSearchFilter): Promise<number> => {
    let aditionalQueryParameters = '';
    if (filter.overriddenOmsetningOnly) {
      aditionalQueryParameters += '&overrideOmsetningOnly=true';
    }
    if (filter.withoutAbonnement) {
      aditionalQueryParameters += '&withoutAbonnement=true';
    }
    if (filter.withoutAvtaler) {
      aditionalQueryParameters += '&withoutAvtaler=true';
    }
    if (filter.withoutFakturaAdresser) {
      aditionalQueryParameters += '&withoutFakturaAdresser=true';
    }
    if (filter.withoutKontaktpersoner) {
      aditionalQueryParameters += '&withoutKontaktpersoner=true';
    }
    if (filter.aktivtAbonnementTjenestegruppeId) {
      aditionalQueryParameters += `&aktivtAbonnementTjenestegruppeId=${filter.aktivtAbonnementTjenestegruppeId}`;
    }
    if (filter.aktivtAbonnementTjenesteId) {
      aditionalQueryParameters += `&aktivtAbonnementTjenesteId=${filter.aktivtAbonnementTjenesteId}`;
    }
    if (filter.aktivAvtaleTjenesteGruppeId) {
      aditionalQueryParameters += `&aktivAvtaleTjenesteGruppeId=${filter.aktivAvtaleTjenesteGruppeId}`;
    }
    if (filter.harDagligLederUtenEpost) {
      aditionalQueryParameters += `&harDagligLederUtenEpost=${filter.harDagligLederUtenEpost}`;
    }
     if (filter.harDagligLederUtenEpost) {
      aditionalQueryParameters += `&harDagligLederUtenEpost=${filter.harDagligLederUtenEpost}`;
    }
    if (filter.manglerDagligLeder) {
      aditionalQueryParameters += `&manglerDagligLeder=${filter.manglerDagligLeder}`;
    }

    return axios
      .get(`${baseUrl}/Count?q=${filter.query}&statusFilter=${filter.statusOption}${aditionalQueryParameters}`)
      .then(response => response.data)
  },

  opprettTsKunde: (command: OpprettTsKundeCommand): Promise<TsKundeDto> =>
    axios
      .post(`${baseUrl}`, command)
      .then(response => response.data),

  editTsKundeOverview: (command: updateTsKundeCommand): Promise<TsKundeDto> =>
    axios
      .put(`${baseUrl}`, command)
      .then(response => response.data),

  deactivateTsKunde: (tsKundeId: string): Promise<TsKundeDto> =>
    axios
      .get(`${baseUrl}/${tsKundeId}/Deactivate`)
      .then(response => response.data),

  deleteTsKunde: (tsKundeId: string): Promise<string> =>
    axios
      .delete(`${baseUrl}/${tsKundeId}`)
      .then(response => response.data),
}


export default TsKunderApi;