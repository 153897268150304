import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import TjenesterApi from "services/AdminportalenApi/TjenesterApi";
import { TjenesteDto } from 'services/AdminportalenApi/TjenesterApi/types';

interface Props {
  selectedId?: string;
  isInvalid?: boolean;
  onHandleChange: (selected: TjenesteDto) => void;
}

const TjenesteTypeahead = (props: Props) => {
  const [query, setQuery] = useState<string>('');
  const [tjenester, setTjenester] = useState<TjenesteDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTjeneste, setSelectedTjeneste] = useState<TjenesteDto>();

  const onChangeHandler = (e: TjenesteDto[]) => {
    if (e.length === 0) {
      props.onHandleChange(undefined);
      setSelectedTjeneste(undefined);
    } else {
      props.onHandleChange(e[0]);
      setSelectedTjeneste(e[0]);
    }
  };

  const searchTjenester = async () => {
    setIsLoading(true);
    const result = await TjenesterApi.search(query);
    setTjenester(result);
    setIsLoading(false);
  };

  const loadTjeneste = async (id: string) => {
    const result = await TjenesterApi.getById(id);
    setSelectedTjeneste(result);
  };

  useEffect(() => {
    if (query && query.length > 2) {
      searchTjenester();
    }
  }, [query]);

  useEffect(() => {
    if (props.selectedId) {
      loadTjeneste(props.selectedId)
    }
  }, [props.selectedId]);

  return (
    <AsyncTypeahead
      id="tjenesteTypeahead"
      clearButton
      labelKey={(option: TjenesteDto) => `${option.tjenesteNavn} - ${option.tjenesteGruppeNavn}`}
      minLength={0}
      delay={100}
      isLoading={isLoading}
      options={tjenester}
      searchText="Søker"
      placeholder="Søk etter tjenester"
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      useCache={false}
      maxResults={10}
      onInputChange={debounce(e => setQuery(e), 300)}
      onChange={(e: any) => onChangeHandler(e)}
      onSearch={debounce(q => setQuery(q), 300)}
      selected={selectedTjeneste ? [selectedTjeneste] : []}
      isInvalid={props.isInvalid} />
  )
}

export default TjenesteTypeahead;