import { useState } from 'react'
import { Row, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import cx from 'classnames';
import Style from './index.module.css';
import PopoverMenu from 'components/PopoverMenu';
import useToaster from 'utils/hooks/useToaster';
import TjenestePriserApi from 'services/AdminportalenApi/TjenestePriserApi';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import CreateTjenestePris from './components/CreateTjenestePris';
import EditTjenestePris from './components/EditTjenestePris';
import { TjenesteMedPriserDto } from 'services/AdminportalenApi/TjenesterApi/types';
import { TjenestePrismedIntervallDto } from 'services/AdminportalenApi/TjenestePriserApi/types';
import { useNavigate } from 'react-router-dom';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import IcebergBadge from 'components/IcebergBadge';

interface Props {
	tjeneste: TjenesteMedPriserDto;
	onUpdated: () => void;
}

const TjenestePrisCard = (props: Props) => {
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	const toaster = useToaster();
	const navigate = useNavigate();
	const [selectedTjenesteprismedIntervall, setSelectedTjenesteprismedIntervall] = useState<TjenestePrismedIntervallDto>(null);

	//edit 
	const handleEdit = async (tjenesteprisId: string) => {
		const result = await TjenestePriserApi.getTjenestePris(tjenesteprisId);
		setSelectedTjenesteprismedIntervall(prev => result);
		setShowEditModal(true);
	}

	// Delete method
	const handleDelete = async (tjenestePrisId: string) => {
		try {
			await TjenestePriserApi.deleteTjenestePris(tjenestePrisId);
			toaster.success('Suksess', 'Tjenestepris slettet!');
			goToTjenesteDetails(props.tjeneste.tjenesteId);
			props.onUpdated();
		} catch (err) {
			toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
		}
	};

	const goToTjenesteDetails = (tjenesteId: string) => {
		navigate(`/Tjenester/${tjenesteId}/Details`);
	};

	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	return (
		<>
			<IconButton
				icon="plus"
				variant="outline-primary"
				onClick={() => setShowCreateModal(true)}
				className='col'
				style={{ margin: '10px' }}
			>
				pris
			</IconButton>


			{props.tjeneste.tjenestePris.length <= 0 && (
				<div className={Style.hjelptekst}>
					Ingen pris data eksisterer, trykk på knappen over for å opprette en ny pris.
				</div>
			)}

			<div className={Style.wrapper}>
				{(props.tjeneste.tjenestePris.length > 0) &&
					<Row>
						<Table responsive className={cx(Style.table, [Style.withHelpText])}>
							<thead className={Style.thead}>
								<tr>
									<th>Status</th>
									<th>Pris (NOK)</th>
									<th>Trappetrinn prismodell?</th>
									<th>
										<span onClick={() => setShowCreateModal(true)}>
											<IcebergIcon icon='plus' title='Ny' bold />
										</span>
									</th>
								</tr>
							</thead>
							<tbody className={Style.tbody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
								{props.tjeneste.tjenestePris.map(tp => <tr key={tp.tjenesteId}>
									<td>
										{
											tp.erAktiv ?
												<IcebergBadge variant="primary">AKTIV</IcebergBadge> :
												(
													tp.validFromDate.getTime() - currentDate.getTime() > 0 ?
														<IcebergBadge variant='warning'>FREMTIDIG</IcebergBadge> :
														<IcebergBadge variant='dark'>INAKTIV</IcebergBadge>
												)
										}
										{' '}
										({format(tp.validFromDate, 'dd.MM.yyyy')} - )
									</td>
									<td width={88} style={{ textAlign: 'end' }}>{tp.pris.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>
									<td>{tp.prisIntervall.length > 0 && (tp.prisModelType === 3 || tp.prisModelType === 2) ? "Ja" : "Nei"} </td>
									<td width={35} className={Style.tdPopOverMenu}>
										<PopoverMenu
											onDelete={() => handleDelete(tp.tjenestePrisId)}
											onEdit={() => handleEdit(tp.tjenestePrisId)}
											confirmDelete={true} />
									</td>
								</tr>
								)}
							</tbody>
						</Table>
					</Row>}
			</div>

			{showEditModal &&
				<EditTjenestePris
					show={showEditModal}
					setShow={setShowEditModal}
					onUpdated={props.onUpdated}
					tjenestePris={selectedTjenesteprismedIntervall}
				/>}

			<CreateTjenestePris
				show={showCreateModal}
				setShow={setShowCreateModal}
				onUpdated={props.onUpdated}
				tjenesteId={props.tjeneste.tjenesteId} />

		</>
	);
};

export default TjenestePrisCard;