import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import IcebergBadge from "components/IcebergBadge";
import { format } from "date-fns";
import { useState } from "react";
import { Col, Row, Table, OverlayTrigger, Popover, Badge } from "react-bootstrap";
import { Fakturagrunnlag, Linje } from "services/AdminportalenApi/FakturagrunnlagApi/types";
import formatAsCurrency from "utils/formatAsCurrency";
import Style from './index.module.scss';

interface Props {
	grunnlag: Fakturagrunnlag;
	onDeaktiverLinje: (linjeId: string) => void;
}

interface ActionMenuProps {
	onDeaktiverLinjeClicked: () => void;
	linje: Linje;
	disableDeaktiver: boolean;
}

const OmsetningsKlassePopover = (omsetningsklasse: string) => {
	let msg = 'Omsetningsklasse ' + omsetningsklasse;
	switch (omsetningsklasse) {
		case "E":
			msg = msg + `: mindre enn ${"100000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
			break;
		case "D":
			msg = msg + `: mellom ${"100000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"250000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
			break;
		case "C":
			msg = msg + `: mellom ${"250000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
			break;
		case "B":
			msg = msg + `: mellom ${"500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"1000000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
			break;
		case "A":
			msg = msg + `: mellom ${"1000000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"1500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
			break;
		case "XL":
			msg = msg + `: over ${"1500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
			break;
	}
	return (
		<Popover id="popover-menu" className={Style.popover}>
			<Popover.Body>
				<p>{msg}</p>
			</Popover.Body>
		</Popover>
	)
}

const ActionMenuFakturalinje = (props: ActionMenuProps) => {
	const [show, setShow] = useState(false);
	return (<OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose show={show} onToggle={setShow}
		overlay={
			<Popover id={`popover-positioned-${'left'}`}>
				<Popover.Body key="fdg">
					{props.linje.kanDeaktiveres && <div>
						<IconButton disabled={props.disableDeaktiver} title={props.disableDeaktiver ? 'Kan ikke endre på et allerede ekportert grunnlag' : 'Deaktiver linje'} icon="trash" variant="outline-danger" onClick={props.onDeaktiverLinjeClicked}>
							Deaktiver
						</IconButton>
					</div>}
					{/* <div>
                    <Button variant="outline-primary" style={{ border: '0px' }} onClick={() => { }}>
                        Send til POGO
                    </Button>
                </div>
                <div>
                    <Button variant="outline-primary" style={{ border: '0px' }} onClick={() => { }}>
                        Reklakuler rabatter
                    </Button>
                </div>
                <div>
                    <Button variant="outline-primary" style={{ border: '0px' }} onClick={() => { }}>
                        Slett periode
                    </Button>
                </div>
                <div>
                    <Button variant="outline-primary" style={{ border: '0px' }} onClick={() => { }}>
                        Slett tjeneste
                    </Button>
                </div> */}
				</Popover.Body>
			</Popover>
		}>
		<button style={{ border: 'none', background: 'transparent', padding: 0 }}>
			<IcebergIcon icon="ellipsis" />
		</button>
	</OverlayTrigger>)
}

const FakturaPreview = (props: Props) => {

	const [valgtLinje, setValgtLinje] = useState<Linje | undefined>();

	const toggleSelectedLinje = (linje: Linje) => {
		if (valgtLinje?.linjeId === linje?.linjeId) {
			setValgtLinje(undefined);
		} else {
			setValgtLinje(linje);
		}
	};

	return (
		<div className={Style.invoiceContainer}>
			<Row>
				<Col>
					{props.grunnlag.kundenummer && (
						<div><span>Kundenummer:</span><span style={{ fontWeight: '600' }}> {props.grunnlag.kundenummer}</span></div>
					)}
					{!props.grunnlag.kundenummer && (
						<IcebergBadge variant="warning">Mangler kundenummer</IcebergBadge>
					)}
					<div><span>PO nummer:</span><span style={{ fontWeight: '600' }}> {props.grunnlag.poNummer || "Ikke registrert"}</span></div>
				</Col>
				<Col className="d-flex justify-content-center">
					{props.grunnlag.omsetning > 0 && (
						<div >
							<span>Omsetningsklasse: </span>
							<span>
								{
									props.grunnlag.omsetning > 0 && props.grunnlag.omsetningsKlasse &&
									<OverlayTrigger rootClose placement="bottom" trigger={["hover", "focus"]}
										overlay={OmsetningsKlassePopover(props.grunnlag.omsetningsKlasse)}>
										<IcebergBadge variant="primary">{props.grunnlag.omsetningsKlasse}</IcebergBadge>
									</OverlayTrigger>
								}
							</span>
						</div>
					)}
					{props.grunnlag.omsetning == 0 && (
						<IcebergBadge variant="warning">Mangler omsetning</IcebergBadge>
					)}
				</Col>
				<Col className="d-flex flex-column alight-items text-end">
					<div><span>Deres referanse:</span><span style={{ fontWeight: '600' }}> {props.grunnlag.tsKundeKontaktpersonNavn}</span></div>
					<div><span>Vår referanse:</span><span style={{ fontWeight: '600' }}> {props.grunnlag.tjenesteKontaktpersonNavn}</span></div>
				</Col>
			</Row>
			<Table responsive borderless hover style={{ marginTop: '20px' }} className={Style.invoiceLinesTable}>
				<thead>
					<tr>
						<th></th>
						<th></th>
						<th>Antall</th>
						<th className={Style.rightAlign}>Stykkpris</th>
						<th className={Style.rightAlign}>Netto beløp</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{props.grunnlag.linjer.map(l => (
						<>
							<tr key={`${props.grunnlag.id}-${l.tjenesteId}`}>
								<td>
									<span title={l.deaktivert ? "Linje er deaktivert" : ""} className={l.deaktivert ? "deactivated-currency" : ""}>
										{l.tjenesteNavn} Gjelder: {l.gjelder} {l.startTrappetrinnBeregningFraVolum && <IcebergIcon icon="info" title={`Rabattberegning starter fra antall ${l.startTrappetrinnBeregningFraVolum}`} />}
									</span>
								</td>
								<td></td>
								<td>{l.antall}</td>
								<td className={Style.rightAlign}>{formatAsCurrency(l.stykkPris)}</td>
								<td className={Style.rightAlign}>{formatAsCurrency(l.nettoBelop)}</td>
								<td className={Style.fitContent}>
									<span onClick={() => toggleSelectedLinje(l)} >
										<IcebergIcon icon={l.linjeId === valgtLinje?.linjeId ? "chevron-up" : "chevron-down"} cursor="pointer" />
									</span>								</td>
								<td className={Style.fitContent}>
									<ActionMenuFakturalinje disableDeaktiver={!!props.grunnlag.eksportHodeId} linje={l} onDeaktiverLinjeClicked={() => props.onDeaktiverLinje(l.linjeId)} />
								</td>
								<td></td>
							</tr>
							{valgtLinje && (valgtLinje.linjeId === l.linjeId) && (
								valgtLinje.detaljer.map(d => (
									<tr key={`${props.grunnlag.id}-${l.linjeId}-${d.linjedetaljerId}`}>
										<td>
											<span title={l.deaktivert ? "Linje er deaktivert" : ""} style={l.deaktivert ? { textDecoration: "line-through" } : {}}>
												{d.description}
											</span>
										</td>
										<td>{d.arbeidUtfort ? format(d.arbeidUtfort, 'dd.MM.yy') : ""}</td>
										<td>{d.antall}</td>
										<td className={Style.rightAlign}>{formatAsCurrency(d.stykkPris)}</td>
										<td className={Style.rightAlign}>{formatAsCurrency(d.nettoBelop)}</td>
										<td></td>
										<td></td>
									</tr>
								))
							)}
						</>
					))}

				</tbody>
				<tfoot>
					<tr>
						<td colSpan={5} style={{ textAlign: "right" }}>Totalt: {formatAsCurrency(props.grunnlag.nettoBelop)}</td>
						<td colSpan={3}></td>
					</tr>
				</tfoot>
			</Table>
		</div>
	)
};

export default FakturaPreview;