import { useState } from 'react'
import { Table } from 'react-bootstrap';
import PopoverMenu from 'components/PopoverMenu';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import SearchWordsApi from 'services/AdminportalenApi/SearchWordsApi';
import CreateSearchWord from './components/CreateSearchWord';

interface Props {
	searchWords: string[];
	tsKundeId: string;
	isNedlagt: boolean;
	onUpdated: () => void;
}


const SearchWord = (props: Props) => {
	const toaster = useToaster();

	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const handleDelete = async (word: string) => {
		try {
			await SearchWordsApi.delete(props.tsKundeId, word);
			toaster.success('Suksess', 'Søkeord slettet!');
			props.onUpdated();
		} catch (err) {
			toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
		}
	}

	return (
		<>
			<div className={Style.wrapper}>
				<Table responsive className={Style.table} hover>
					<thead>
						<tr>
							<th>Søkeord</th>
							<th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
								{!props.isNedlagt && (
									<span onClick={() => setShowCreateModal(true)} >
										<IcebergIcon icon='plus' title='Ny' bold />
									</span>
								)}
							</th>
						</tr>
					</thead>
					<tbody className={Style.tbody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
						{props.searchWords.map(word => (
							<tr key={word}>
								<td>{word}</td>
								<td className={Style.tdPopoverMenu}>
									<PopoverMenu
										onDelete={() => handleDelete(word)}
										confirmDelete={true}
										onlyView={props.isNedlagt} />
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			<CreateSearchWord tsKundeId={props.tsKundeId} onUpdated={props.onUpdated} show={showCreateModal} setShow={setShowCreateModal} />
		</>
	)
}

export default SearchWord