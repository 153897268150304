import axios from "axios";
import { getBaseUrl } from "..";
import {
    Fakturagrunnlag,
    FakturagrunnlagOverviewDto,
    FakturagrunnlagPeriodeOverviewDto,
    FakturerBarTjeneste,
    GenererFakturagrunnlagForAbonnementCommand,
    GenererFakturagrunnlagForAbonnementOldCommand,
    GenererFakturagrunnlagForAbonnementResponse,
    SendTilPogoCommand,
    SendTilPogoCommandResult,
    SlettPeriodeCommand,
    SlettPeriodeCommandResult,
    UtestaaendeFakturaPeriode,
    GenererFakturagrunnlagForAbonnementOldResponse,
    TjenestegruppeDto
} from "./types";

const baseUrl = getBaseUrl() + '/Fakturagrunnlag';

const FakturagrunnlagApi = {

    getYearOversikt: async (tjenesteGruppeId: string, year: number): Promise<FakturagrunnlagOverviewDto[]> => {
        const response = await axios.get(`${baseUrl}/AsOverview?tjenesteGruppeId=${tjenesteGruppeId}&year=${year}`);
        return response.data;
    },
    getPeriodeOversikt: async (tjenesteGruppeId: string, periode: string): Promise<FakturagrunnlagPeriodeOverviewDto[]> => {
        const response = await axios.get(`${baseUrl}/AsPeriodeOverview?tjenesteGruppeId=${tjenesteGruppeId}&periode=${periode}`);
        return response.data;
    },
    getGrunnlag: async (id: string): Promise<Fakturagrunnlag> => {
        const response = await axios.get(`${baseUrl}/${id}`);
        return response.data;
    },
    exportAsCsv: async (tjenesteGruppeId: string, periode: string): Promise<any> => {
        const response = await axios({
            url: `${baseUrl}/export-period-as-csv?tjenesteGruppeId=${tjenesteGruppeId}&periode=${periode}`,
            method: 'GET',
            responseType: 'blob',
        });

        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `Fakturagrunnlag_${tjenesteGruppeId}_${periode}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    },
    getTjenesterThatIsToBeInvoiced: async (tjenesteGruppeId: string, year: number, quarter?: number, month?: number): Promise<FakturerBarTjeneste[]> => {
        const response = await axios.get(`${baseUrl}/fakturerBareTjenester?tjenesteGruppeId=${tjenesteGruppeId}&year=${year}&quarter=${quarter || ''}&month=${month || ''}`);
        return response.data;
    },
    deaktiverFakturaLinje: async (fakturagrunnlagId: string, linjeId: string): Promise<void> => {
        const response = await axios.post(`${baseUrl}/${fakturagrunnlagId}/deaktiverLinje/${linjeId}`);
        return response.data;
    },
    deletePeriod: async (tjenesteGruppeId: string, periode: string, force: boolean): Promise<SlettPeriodeCommandResult> => {
        const command: SlettPeriodeCommand = {
            periode: periode,
            tjenesteGruppeId: tjenesteGruppeId,
            force: force
        };
        const response = await axios.post(`${baseUrl}/slettPeriode`, command);
        return response.data;
    },
    genererFakturagrunnlagForAbonnementOld: async (command: GenererFakturagrunnlagForAbonnementOldCommand): Promise<GenererFakturagrunnlagForAbonnementOldResponse> => {
        const response = await axios.post(`${baseUrl}/genererFakturagrunnlagForAbonnementOld`, command);
        return response.data;
    },
    sendTilPogo: async (command: SendTilPogoCommand): Promise<SendTilPogoCommandResult> => {
        const response = await axios.post(`${baseUrl}/SendTilPogo`, command);
        return response.data;
    },
    førsteFakturerbarePeriode: async (tjenesteGruppeId: string): Promise<string> => {
        const response = await axios.get(`${baseUrl}/${tjenesteGruppeId}/FørsteFakturerbarePeriode`);
        return response.data;
    },
    utestaaendeFakturaResult: async (tjenesteGruppeId: string, year: number, quarter?: number, month?: number, tsKundeIds?: string): Promise<UtestaaendeFakturaPeriode[]> => {
        let url = `${baseUrl}/utestående?tjenestegruppeId=${tjenesteGruppeId}&year=${year}`;
        if (quarter) {
            url += `&quarter=${quarter}`;
        }
        if (month != undefined) {
            url += `&month=${month + 1}`; // 0 index based
        }
        if (tsKundeIds) {
            url += `&tsKundeIds=${tsKundeIds}`
        }
        const response = await axios.get(url);
        return response.data;
    },
    tjenestegrupperRelevantForFakturagrunnlag: async (): Promise<TjenestegruppeDto[]> => {
        const response = await axios.get(`${baseUrl}/tjenesteGrupperRelevantForAbonnement`);
        return response.data;
    },
    genererFakturagrunnlagForAbonnement: async (command: GenererFakturagrunnlagForAbonnementCommand): Promise<GenererFakturagrunnlagForAbonnementResponse> => {
        const response = await axios.post(`${baseUrl}/genererFakturagrunnlagForAbonnement`, command);
        return response.data;
    }
};

export default FakturagrunnlagApi;