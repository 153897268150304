import { IconButton } from "@tradesolution/iceberg-ui-react";
import { Card, Form, Table } from "react-bootstrap";
import { PrisIntervallItem } from "services/AdminportalenApi/TjenestePriserApi/types";

interface Props {
  prisIntervall: PrisIntervallItem[];
  onPriceModelUpdated: (varianter: PrisIntervallItem[]) => void;
}

const NivaaBasertPrisModell = (props: Props) => {

  const updatePris = (index: number, value: string): void => {
    if (isNaN(Number(value))) {
      return;
    }
    const updated = props.prisIntervall.map((nivaa, i) =>
      i === index ? { ...nivaa, pris: Number(value) } : nivaa
    );
    props.onPriceModelUpdated(updated);
  };

  const updateAntall = (index: number, value: string): void => {
    if (isNaN(Number(value))) {
      return;
    }
    
    const updated = props.prisIntervall.map((nivaa, i) =>
      i === index ? { ...nivaa, antall: Number(value) } : nivaa
    );
    props.onPriceModelUpdated(updated);
  };

  const removeItem = (index: number): void => {
    const updatedVarianter = props.prisIntervall.filter((variant, i) => i !== index);
    props.onPriceModelUpdated(updatedVarianter);
};

  return (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title>Nivåer</Card.Title>
        <Table>
          <thead>
            <tr>
              <th>Antall</th>
              <th>Pris</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.prisIntervall.map((variant, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                      value={variant.antall}
                      onChange={(e) => updateAntall(index, e.target.value)}
                    />
                </td>
                <td>
                  <Form.Control
                    value={variant.pris}
                    onChange={(e) => updatePris(index, e.target.value)}
                  />
                </td>
                <td className="text-end">
                    <IconButton icon="trash" size="sm" title="Fjern" variant="outline-danger" onClick={() => removeItem(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <IconButton 
            icon="plus" 
            size="sm"
            variant="outline-primary" 
            onClick={() => props.onPriceModelUpdated([...props.prisIntervall, { pris: 0, antall: 0 }])}>Legg til</IconButton>
      </Card.Body>
    </Card>
  );
};

export default NivaaBasertPrisModell;
