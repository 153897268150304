import { Form, Modal, Spinner, Table } from 'react-bootstrap';
import TjenestePriserApi from 'services/AdminportalenApi/TjenestePriserApi';
import { PrisIntervallItem, TjenestePrismedIntervallDto, UpdateTjenestePrisCommand } from 'services/AdminportalenApi/TjenestePriserApi/types';
import DatePicker from 'components/DatePicker';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import cx from 'classnames';
import Style from './index.module.css';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import OmsetningVolumMatrix, { PriceModelMatrice } from '../OmsetningVolumMatrix';
import NivaaBasertPrisModell from '../NivaaBasertPrisModel';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  tjenestePris: TjenestePrismedIntervallDto;
  onUpdated: () => void;
}

const EditTjenestePris = (props: Props) => {
  const toaster = useToaster();

  const initialValues: UpdateTjenestePrisCommand = {
    tjenestePrisId: props.tjenestePris.tjenestePrisId,
    tjenesteId: props.tjenestePris.tjenesteId,
    pris: props.tjenestePris.pris,
    description: props.tjenestePris.description,
    prisModelType: props.tjenestePris.prisModelType,
    validFromDate: props.tjenestePris.validFromDate,
    prisIntervaller: props.tjenestePris.prisIntervall || []
  }

  const validate = (values: UpdateTjenestePrisCommand) => {
    let errors: any = {};
    if (values.pris === -1) {
      errors.pris = 'Pris kan ikke være negativ';
    }

    if (values.validFromDate === null) {
      errors.validFromDate = "Må velges en dato. Datofeltet kan ikke være null";
    }

    /* var s = new Set(values.prisIntervaller.map(x => x.antall));
    if (s.size < values.prisIntervaller.length) {
      errors.prisIntervallUpdates = "To eller flere rader har samme Antall";
    } */

    return errors;
  }

  const onSubmit = async (command: UpdateTjenestePrisCommand) => {
    try {
      await TjenestePriserApi.updateTjenestePris(command.tjenestePrisId, command);
      toaster.success('Suksess', 'Tjeneste pris oppdatert!');
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, loading, errors, touched, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  const handleAddPrisintervall = () => {
    const oppdatertePrisIntervall = [...values.prisIntervaller, { antall: 0, pris: 0.0 }]
    handleChange('prisIntervaller', oppdatertePrisIntervall);
  }

  const handleDeletePrisIntervall = (prisIntervall: PrisIntervallItem) => {
    const oppdatertePrisIntervall = values.prisIntervaller.filter(x => x !== prisIntervall);
    handleChange('prisIntervaller', oppdatertePrisIntervall);
  }

  const handleUpdateAntall = (antall: number, index: number) => {
    const oppdatertePrisAntall = [...values.prisIntervaller];
    oppdatertePrisAntall[index].antall = antall;
    handleChange('prisIntervaller', oppdatertePrisAntall);
  }

  const handleUpdatePris = (pris: number, index: number) => {
    const oppdatertePris = [...values.prisIntervaller];
    oppdatertePris[index].pris = pris;
    handleChange('prisIntervaller', oppdatertePris);
  };

  const handlePriceModelMatriceUpdated = (priceModelMatrice: PriceModelMatrice) => {
    const flattened = priceModelMatrice.getPrisIntervall();
    handleChange('prisIntervaller', flattened);
  };
  
    const handleNivaaBasertPrisModellUpdated = (prisIntervall: PrisIntervallItem[]): void => {
      handleChange('prisIntervaller', prisIntervall);
    }

  return (
    <>
      <Modal
        centered show={props.show} size="xl" onHide={() => props.setShow(false)} dialogClassName="modal-10w">

        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">Rediger tjenestepris</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Form.Group className="mb-3">
              <Form.Label>NOK</Form.Label>
              <Form.Control
                value={values.pris}
                step=".01"
                min={0}
                type='number'
                onChange={e => handleChange('pris', parseFloat(e.target.value))}
                isInvalid={errors.pris}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pris}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gyldig fra og med</Form.Label>
              <DatePicker
                onChange={date => handleChange('validFromDate', date)}
                selected={values.validFromDate}
                isInvalid={errors.validFromDate} />
              <Form.Control.Feedback type="invalid">
                {errors.validFromDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Kommentar</Form.Label>
              <Form.Control
                value={values.description}
                type='text'
                onChange={e => handleChange('description', e.target.value)}
              />
            </Form.Group>


            {/* mål: da man velger enten volumbasert eller omsetningsbasert 
            kan man se på opprett knapp for prisintervall eller skjul denne knappen */}

            {/*må sjekke om det er eksisterende prisintervall eller ny prisitnervall
            hvis eksisterende prisintervall, hent data fra backend men man kunnne redigere pris og antall verdi
            hvis nyprisintervall, opprett ny prisintervall */}

            <Form.Group className="mb-3">
              <Form.Label>Pris/rabatt modell</Form.Label>
              <Form.Select aria-label='PrismodellSelect'
                style={{ marginBottom: 8, cursor: values.prisModelType === 0 ? 'pointer' : 'not-allowed' }}
                value={values.prisModelType}
                onChange={e => {
                  handleChange('prisModelType', parseInt(e.target.value));
                }}
                disabled={values.prisModelType !== 0}>
                <option value="0">Ingen</option>
                <option value="1">Volumbasert trappetrinnsmodell</option>
                <option value="2">Omsetningsbasert modell</option>
                <option value="3">Volum og omsetningsbasert modell</option>
                <option value="4">Nivåbasert modell</option>
              </Form.Select>

              {values.prisModelType === 4 &&
                <NivaaBasertPrisModell prisIntervall={values.prisIntervaller} onPriceModelUpdated={handleNivaaBasertPrisModellUpdated} />
              }

              {values.prisModelType === 3 &&
                <OmsetningVolumMatrix prisIntervall={values.prisIntervaller} onPriceModelUpdated={handlePriceModelMatriceUpdated} />
              }

              {(values.prisModelType === 1 || values.prisModelType === 2) && <div>
                <Table responsive className={cx(Style.table, [Style.withHelpText])}>
                  <thead>
                    <tr>
                      <th>Antall</th>
                      <th>Pris</th>
                      <th style={{ width: '35px' }}>
                        <IconButton
                          icon='plus'
                          className='col'
                          variant='outline-primary'
                          onClick={handleAddPrisintervall} />
                      </th>
                    </tr>
                  </thead>
                  <tbody className={Style.tbody}>
                    {values.prisIntervaller.map((prisIntervall, index) =>
                    (<tr>
                      <td>
                        <Form.Control
                          type='number'
                          value={prisIntervall.antall}
                          min={0}
                          isInvalid={errors.prisIntervallUpdates}
                          onChange={e => handleUpdateAntall(parseInt(e.target.value), index)} />
                      </td>
                      <td>
                        <Form.Control
                          type='number'
                          step=".01"
                          min={0}
                          isInvalid={errors.prisIntervallUpdates}
                          value={prisIntervall.pris}
                          onChange={e => handleUpdatePris(parseFloat(e.target.value), index)} />
                      </td>
                      <td>
                        <IconButton icon='trash' variant='outline-danger' onClick={() => handleDeletePrisIntervall(prisIntervall)} />
                      </td>
                    </tr>)
                    )}
                  </tbody>
                </Table>
                {errors?.prisIntervallUpdates && touched?.prisIntervaller && <div className={Style.invalidFeedback}>{errors?.prisIntervallUpdates}</div>}
              </div>}
            </Form.Group>


          </Modal.Body>

          <Modal.Footer>

            <IconButton icon='close' className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
              Avbryt
            </IconButton>
            <IconButton
              variant="primary"
              icon='disk'
              className="col"
              type='submit'
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
              Lagre
              {
                loading &&
                <Spinner as="span" animation="border" size='sm' role="status" aria-hidden="true" />
              }
            </IconButton>
          </Modal.Footer>
        </Form>
      </Modal>

    </>
  );
};

export default EditTjenestePris;