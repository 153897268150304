import React, { useEffect, useState } from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import TjenesterApi from 'services/AdminportalenApi/TjenesterApi';
import { TjenesteMedPriserDto } from 'services/AdminportalenApi/TjenesterApi/types';

import Style from './index.module.css';
import EditTjeneste from './components/EditTjeneste';
import TjenestePrisCard from './components/TjenestePrisCard';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

const TjenesteDetails = () => {
  const navigate = useNavigate();
  const params = useParams<{ tjenesteId: string }>();

  const [showRedigerModal, setShowRedigerModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tjeneste, setTjeneste] = useState<TjenesteMedPriserDto>();

  const loadTjeneste = async () => {
    setIsLoading(true);
    const e = await TjenesterApi.getTjenesteMedPris(params.tjenesteId);
    if (!e) {
      navigate("/NotFound");
    }
    setTjeneste(e);
    setIsLoading(false);
  };

  useEffect(() => {
    loadTjeneste();
  }, [params.tjenesteId]);

  if (!tjeneste) {
    return <div style={{ fontSize: '25px' }}>
      Laster...
    </div>
  }

  return (
    <>
      <div style={{ padding: '10px' }}>
        <Row className={Style.OverviewContainer}>
          <Col>
            <Row>
              <div className={Style.tjenesteName}>
                <b>{tjeneste.tjenesteNavn}
                </b>
              </div>
            </Row>
          </Col>

          <Col>
            <p className={Style.category}>Tjenestegruppenavn</p>
            <span className={Style.categoryContent}> {tjeneste.tjenesteGruppeNavn}</span>

            <p className={Style.category}>Produktnummer</p>
            <span className={Style.categoryContent}> {tjeneste.produktnummer}</span>

            <p className={Style.category}>Avdelingsnummer</p>
            <span className={Style.categoryContent}> {tjeneste.avdelingsnummer}</span>

          </Col>

          <Col>
            <p className={Style.category}> Fakturerers forskuddsvis?</p>
            <span className={Style.categoryContent}> {tjeneste.faktureresForskuddsvis ? 'Ja' : 'Nei'}</span>

            <p className={Style.category}>Salgskonto</p>
            <span className={Style.categoryContent}> {tjeneste.salgskonto}</span>

            <p className={Style.category}>Momsfritatt salgskonto</p>
            <span className={Style.categoryContent}> {tjeneste.momsFritattSalgskonto}</span>
          </Col>


          <Col>
            <p className={Style.category}> Faktureringsintervall</p>
            <span className={Style.categoryContent}> {tjeneste.faktureringsIntervall}</span>

            <p className={Style.category}>Abonnement</p>
            <span className={Style.categoryContent}> {tjeneste.antallAktiveAbonnement} aktive, {tjeneste.antallInaktiveAbonnement} inaktive</span>

            <p className={Style.category}>Kommentar</p>
            <span className={Style.categoryContent}> {tjeneste.description}</span>
          </Col>


          <Col>
            <div className='float-end'>
              <EditTjeneste
                show={showRedigerModal}
                setShow={setShowRedigerModal}
                tjeneste={tjeneste}
                onlyView={false}
                onUpdated={() => loadTjeneste()}
                onDeleted={() => navigate('/Tjenester')}
              />
            </div>

          </Col>

        </Row>


        <div>
          <TjenestePrisCard tjeneste={tjeneste} onUpdated={() => loadTjeneste()} />
        </div>

      </div>
    </>
  );
};

export default TjenesteDetails;