import IcebergBadge from "components/IcebergBadge";

interface Props {
  activeFilters: { label: string; removeAction: () => void }[];
  filter: { statusOption: string };
  onClearAllFilters: () => void;
  onClearFilter: (label: string) => void;
}

const FilterTags = (props: Props) => {
  const handleNullstill = () => {
    props.onClearAllFilters();
  };

  const showClearAllButton = !(
    props.activeFilters.length === 1 && props.filter.statusOption === "Aktive"
  );

  const ClearAllButton = () => (
      <div
        onClick={handleNullstill}
        style={{
          cursor: "pointer",
          display: "inline-flex",
          verticalAlign: "middle",
        }}
      >
        <IcebergBadge variant="primary">
          <span style={{ verticalAlign: "middle" }}>Nullstill</span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleNullstill();
            }}
            style={{
              marginLeft: "4px",
              padding: "0",
              verticalAlign: "middle",
              paddingTop: "2px",
              paddingBottom: "2px",
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: "inherit",
            }}
          >
            x
          </button>
        </IcebergBadge>
      </div>
    );

  return (
    <>
      {showClearAllButton && <ClearAllButton />}
      {props.activeFilters.map((filter, index) => (
        <div
          key={index}
          onClick={() => props.onClearFilter(filter.label)}
          style={{
            cursor: showClearAllButton ? "pointer" : "default",
            display: "inline-flex",
            verticalAlign: "middle",
            margin: "2px",
          }}
        >
          <IcebergBadge variant="light">
            <span style={{ verticalAlign: "middle" }}>{filter.label}</span>
            {showClearAllButton && <button
              onClick={(e) => {
                e.stopPropagation();
                props.onClearFilter(filter.label);
              }}
              style={{
                marginLeft: "4px",
                padding: "0",
                verticalAlign: "middle",
                paddingTop: "2px",
                paddingBottom: "2px",
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: "inherit",
              }}
            >
              x
            </button>}
          </IcebergBadge>
        </div>
      ))}
    </>
  );
};

export default FilterTags;
